import { Injectable } from '@angular/core';
import { ILayoutConfig, IPageNames, IPageOrigins } from '../../model/layout-config.model';
import { IHost } from '../../model/host.model';
import { ICLientData } from '../../model/client.model';
import { ICurrency } from '../../model/currency.model';
import { Currency, EuropeanCountries, GulfCountries } from '../../model/currency-list';
import { IUser } from '../../model/user.model';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { IFundraiser } from '../../model/fundraiser.model';
import { ITypParams } from '../../model/payment.model';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {
  logoWidth = "1140px";
  clientLocationData$ = new BehaviorSubject<ICLientData | null>(null);
  currencies: ICurrency[] = Currency;
  supportedCurrency: ICurrency[] = Currency;
  supportedSipCurrency: ICurrency[] = Currency;
  currency = '';
  currency$ = new BehaviorSubject<string>('');
  defaultImg = '/assets/images/default-campaign-picture.jpg';
  defaultBlurImg = '/assets/images/defaultBlurImg.png';
  defaultStoryImg = '/assets/images/default/default-story.png';
  defaultSuccessStoryImg = '/assets/images/default/default-success-story.svg';
  deviceType = '';
  domain_details!: IHost;
  europeanCountries = EuropeanCountries;
  fundraiser?: IFundraiser;
  globalVar = new ReplaySubject<any>(1);
  showDockedBtn$ = new BehaviorSubject(false);
  gtmPageData: any;
  gulfCountries = GulfCountries;
  isBrowser = false;
  isDomainLoaded$ = new BehaviorSubject(false);
  isDummyEmail = false;
  isPermanentLoggedIn = false;
  isTempLoggedIn = false;
  isMobile = false;
  isStoryDataLoading = false;
  isToken = false;
  authToken = '';
  isVariableLogin = false;
  campaignType: any;
  metaDataOfPage: any;
  numberChange$ = new Subject<any>();
  pageName: IPageNames = '';
  origin: IPageOrigins = '';
  paymentCurrency = '';
  poweredBy = 'equalall';
  previousPageUrl = '';
  userData$ = new BehaviorSubject<IUser | undefined>(undefined);
  utm_url_obj: any;
  utm_url_string = '';
  varLoginData: { user: any, userdata: any } = { user: null, userdata: null };
  pageLayoutConfig?: ILayoutConfig;
  stringOnlyRegex = /^[a-zA-Z\s]+$/;
  numberOnlyRegex = /^\d+(\.\d+)?$/;
  namePatternRegex = /^[a-z ,.'-]+$/i;
  emailPatternRegex = /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;
  pswPatternRegex = /^(?=.*[a-z])+(?=.*[A-Z])+(?=.*\d).{8,}$/;
  expiryRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
  userPhoneExt = '';
  userPhoneNumber = '';
  loginMethod = '';
  loginType: 'signin' | 'signup' | '' = '';
  dummyEmailExtn = '@dummyequalall.org';
  guestEmailExtn = '@dummyeqall.org';
  isGuestUser = false;
  donateMultiPatient: boolean = false;
  recommendedVpa = '';
  sidebarDrawerState = new BehaviorSubject(false);

  eventInfos = {
    contributeInitiate: { info_1: 'position', info_2: 11, info_3: '' },
    contributeCart: { info_1: '', info_2: '', info_3: '' },
    orderCreated: { info_1: '', info_2: '', info_3: '' },
  }
  tyParams?: ITypParams;
  showSkeletonFor = {
    slab: false
  };

  stripeUsToken: any;
  maxDonationAmount = 50000000;
  waitForTickerData = false;

  minSipAmounts: any = { INR: 100, USD: 10, GBP: 10, EUR: 10, AED: 20, SGD: 30, SAR: 50, CAD: 10, HKD: 10, QAR: 10, AUD: 10, MYR: 10 };
  defaultSipCause = 'war';
  leadMailTo = 'leads.ketto@gmail.com';

  constructor() { }


}
