<div class="drawer-sidenav-wrapper" [class]="profileSideState ? 'open-drawer':''">
  <div class="header">
    <mat-icon (click)="closeSideNav()">close</mat-icon>
    <div class="text">
      Profile
    </div>
  </div>
  <div class="content-wrapper">
    <div class="profile-completion">
      <div class="profile-progress-wrapper">
        <app-profile-progress [percentage]="userData?.profile_complate" />
      </div>
      <div class="profile-data-wrapper">
        <div *ngIf="userData?.full_name" class="name">{{userData?.full_name}}</div>
        <div *ngIf="userData?.email" class="email">{{userData?.email}}</div>
        <div class="complete-profile-wrapper">
          <a (click)="redirect()">
            <button mat-button class="btn">
              Complete Profile
              <mat-icon>chevron_right</mat-icon>
            </button>
          </a>
        </div>
      </div>
    </div>
    <div class="donation-impact-wrapper">
      <div *ngFor="let item of livesImpactedList" class="impact-card">
        <div class="numeric-value">{{item.value}}</div>
        <div class="impact-label">{{item.label}}</div>
      </div>
    </div>
    <div class="menu-list">
      <a *ngFor="let item of menuList" class="item" (click)="redirectionLogic(item)">
        <div class="menu-icon">
          <img [src]="'/assets/images/header/' + item.iconName + '.svg'" alt="menu icon">
        </div>
        <div class="label">{{item?.label}}</div>
        <div class="icon">
          <mat-icon>chevron_right</mat-icon>
        </div>
      </a>
    </div>
  </div>
</div>